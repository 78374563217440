// slick carousel styles override

.slick-dots {
  bottom: -20px;
}

.slick-dots li button:before {
  font-size: 16px;
  line-height: 24px;
  color: #9c9c9c;
}

.slick-dots li.slick-active button:before {
  color: #D91965;
  opacity: 1;
}
