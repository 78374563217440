@import-normalize;
@import "../../assets/styles/index";

body {
  margin: 0;
  font-family: 'Heebo', sans-serif;

  &.no-scroll {
    overflow: hidden;
  }
}

.title {
  margin: 0;
  font-style: normal;
  font-weight: 300;

  &--bold {
    font-weight: 600;
  }

  &--general {
    margin: 0;
    padding: 55px 10px 25px 10px;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #202124;

    @include media(min, tab) {
      padding: 95px 0 30px 0;
      font-size: 64px;
      line-height: 80px;
    }
  }

  &--white {
    color: #FFFFFF;
  }

  &--sub {
    margin: 0;
    padding: 0 10px 20px 10px;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #8F8F91;
  }
}





.section {
  &__content {
    max-width: 1440px;
    margin: 0 auto;
  }

  &--black {
    background-color: #202124;
  }

  &--red {
    background-color: #D91965;
  }

  &--white {
    background-color: #FFFFFF;
  }

  &--grey {
    background-color: #F8F8F8;
  }
}



.list {
  margin: 0;
  padding-left: 0;
  list-style: none;

  & > li {
    margin-bottom: 10px;

    &::before {
      content: "\2022";
      color: #D91965;
      font-size: 30px;
      font-weight: bold;
      display: inline-block;
      vertical-align: middle;
      width: 25px;
    }
  }
}



.input {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  height: 55px;
  padding: 18px 30px;
  border: none;
  border-radius: 100px;
  overflow: hidden;

  font-size: 14px;
  line-height: 14px;
  color: #FFFFFF;
  text-overflow: ellipsis;

  background: #FFFFFF;
  transition: color 0.5s ease, background-color 0.5s ease, border 0.2s ease;

  &:focus {
    outline: 0;
    background-color: #F6F6F6;
    color: #202124;
  }

  &[disabled] {
    background: #F6F6F6;
    border-color: #666666;
    color: #666666;
  }

  &--br-xs {
    border-radius: 5px;
  }

  &--br-sm {
    border-radius: 25px;
  }

  &--color-black {
    color: #202124;
  }

  &--color-grey {
    color: #202124;

    ::-webkit-input-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    :-moz-placeholder{
      color: #ACACAC;
      opacity: 1;
    }

    &:focus,
    &:hover {
      color: #202124;
    }
  }

  &--bg-light-grey {
    background: #F6F6F6;
  }

  &--bg-grey {
    background: #545557;
  }

  &--bg-red {
    background: #e15387;
  }

  &--with-transparency {
    opacity: 0.2;
  }

  &--side-xs {
    padding-left: 5px;
    padding-right: 5px;
  }

  &--text-indent {
    text-indent: 25px;
  }

  &:focus {
    outline: none;
    background-color: #F6F6F6;
    color: #202124;
  }

  &--error {
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 2px;
    border-color: #EC373C;
    color: #EC373C;

    &:hover {
      color: #EC373C;
    }

    &:focus {
      outline: none;
      background-color: #FFFFFF;
      color: #EC373C;
    }
  }

  &--success {
    background-color: #FFFFFF;
    border-width: 2px;
    border-color: #4DAC4A;
    color: #4DAC4A;
  }
}

select.input[multiple],
select.input[size],
textarea.input {
  height: auto;
}

.error {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 12px;
  padding: 2px 0 5px 10px;
  color: #eb5757;

  &__text {
    transform: translateX(15px);
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.3s ease, transform 0.5s ease;

    &--visible {
      transform: translateX(0px);
      opacity: 1;
    }
  }
}




.button {
  display: inline-block;
  box-sizing: border-box;
  padding: 13px 30px;
  border: 2px solid transparent;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  user-select: none;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.4s ease, background-color 0.4s ease, border-color 0.4s ease;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    background-image: linear-gradient(
      transparent,
      rgba(0, 0, 0, 0.05) 50%,
      rgba(0, 0, 0, 0.08)
    );
  }

  &[disabled],
  &.disabled {
    cursor: not-allowed;

    &:hover {
      background-image: none;
    }
  }

  // remove Firefox dotted outline
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &--side-md {
    padding-right: 50px;
    padding-left: 50px;
  }

  &--default {
    border-radius: 100px;
    font-weight: bold;
    color: #ffffff;
    background-color: #D91965;
  }

  &--black {
    background-color: #202124;

    &:hover {
      color: #202124;
      background-color: #FFFFFF;
      border: 2px solid #202124;
      background-image: none;
    }
  }

  &--link {
    &:hover {
      background-image: none;
    }
  }

  &__children {
    display: flex;
  }
}




.logo-link {
  display: inline-block;
  vertical-align: middle;
}




.clearfix {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}




.budges {
  margin: 0 0 20px 0;
  list-style: none;
  padding: 0;

  & > li {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    padding: 8px 15px;
    font-size: 14px;
    line-height: 19px;
    background-color: #D4D3D3;
    border-radius: 18px;
    color: #FFFFFF;
    text-align: center;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}




.estimate-time {
  display: inline-block;
  font-size: 18px;
  line-height: 34px;
  color: #8F8F91;
  padding-left: 35px;
  background-image: url("../../components/svg/Icons/Time.svg");
  background-position: 0 50%;
  background-repeat: no-repeat;
}




.stages {
  &__title {
    margin-bottom: 60px;

    @include media(min, tab) {
      margin-bottom: 110px;
    }
  }

  &__content {
    padding: 0 25px 30px 25px;

    @include media(min, tab) {
      padding: 0 90px 50px 90px;
    }
  }

  &__item {
    margin-bottom: 80px;

    @include media(min, tablet) {
      margin-bottom: 140px;
    }
  }
}

.stage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include media(min, tablet) {
    flex-direction: row;
  }

  &--description-left {
    @include media(min, tablet) {
      flex-direction: row-reverse;
    }
  }

  &__image-wrap {
    max-width: 380px;
    margin-bottom: 40px;
    flex-grow: 1;
    text-align: center;

    @include media(min, tablet) {
      max-width: 560px;
      margin-bottom: 0;
      margin-right: 50px;
    }

    .stage--description-left & {
      @include media(min, tablet) {
        margin-right: 0;
        margin-left: 50px;
      }
    }
  }

  &__description {
    flex-grow: 1;

    @include media(min, tablet) {
      max-width: 700px;
    }
  }

  &__image {
    width: 100%;
    height: auto;
  }

  &__number {
    font-weight: bold;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #ACACAC;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 36px;
    margin-bottom: 30px;
  }

  &__description-text {
    font-size: 18px;
    line-height: 28px;
    color: #8F8F91;
    margin-bottom: 30px;
  }

  &__list {
    font-size: 18px;
    color: #8F8F91;
    margin-bottom: 30px;

    & > li {
      line-height: 28px;
    }
  }
}




.slick-slide {
  visibility: hidden;
}

.slick-slide.slick-active {
  visibility: visible;
}
