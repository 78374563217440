// import media queries breakpoints
@import "variables";

@mixin media($point, $devices) {
  @if $point == max {
    @if $devices == mobile {
      @media screen and (max-width: $tab - 1) {
        @content;
      }
    }

    @if $devices == tab {
      @media screen and (max-width: $tablet - 1) {
        @content;
      }
    }

    @if $devices == tablet {
      @media screen and (max-width: $pc - 1) {
        @content;
      }
    }
  } @else if $point == min {
    @if $devices == mobile {
      @media screen and (min-width: $mobile) {
        @content;
      }
    }

    @if $devices == tab {
      @media screen and (min-width: $tab) {
        @content;
      }
    }

    @if $devices == tablet {
      @media screen and (min-width: $tablet) {
        @content;
      }
    }

    @if $devices == pc {
      @media screen and (min-width: $pc) {
        @content;
      }
    }
  }
}

@mixin media-full($point, $devices) {
  @if $point == full {
    @if $devices == tab {
      @media screen and (min-width: $tab + 1) and (max-width: $tablet - 1) {
        @content;
      }
    }

    @if $devices == tablet {
      @media screen and (min-width: $tablet) and (max-width: $pc - 1) {
        @content;
      }
    }
  }
}