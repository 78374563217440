@import "../../assets/styles/index";

.case-next {
  &__content {
    padding: 30px 25px 60px 25px;

    @include media(min, tab) {
      padding: 30px 90px 60px 90px;
    }
  }

  &__background-wrap {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }

  &__content-wrap {
    margin-top: 210px;

    @include media(min, tab) {
      margin-top: 420px;
    }
  }

  &__fidelity {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  &__title {
    float: left;
    font-size: 42px;
    line-height: 60px;
    color: #FFFFFF;
    margin-bottom: 15px;

    @include media(min, tab) {
      font-size: 60px;
      line-height: 80px;
    }
  }

  &__next-link {
    display: inline-block;
    float: right;
    margin-top: 30px;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-right: 20px;
    background-image: url("../../components/svg/Icons/Right-arrow.svg");
    background-repeat: no-repeat;
    background-position: 100% 30%;
    text-decoration: none;

    &:hover {
      color: #ececec;
      text-decoration: underline;
    }
  }
}
